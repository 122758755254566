<template>
    <div class="container">
        <div class="swimming-pool">
            <p class="swimmingTitle1">{{ $t('serviceSwimmingPool.title1')}}</p>
            <p class="detail-price">{{ $t('serviceSwimmingPool.title2')}}</p>
            <div class="row">
                <div class="col-sm-6">
                    <img v-lazy="{src: require('@/assets/pool/B34C663B-D7FA-4C50-92C2-ED945CC045EE.webp')}" class="img-swimming-pool">
                </div>
                <div class="col-sm-6">
                    <img v-lazy="{src: require('@/assets/pool/0D497EB0-4CDD-4BB2-AE31-61BF0BA28F7C.webp')}" class="img-swimming-pool">
                </div>
            </div>
            <div class="row row-build" v-for="(item, index) in items" :key="index">
                <div class="column col-swim"><img v-lazy="{src: item.img1}" alt="" onclick="openModal();currentSlide(1)" class="hover-shadow cursor i-swim"></div>
                <div class="column col-swim"><img v-lazy="{src: item.img2}" alt="" onclick="openModal();currentSlide(2)" class="hover-shadow cursor i-swim"></div>
                <div class="column col-swim"><img v-lazy="{src: item.img3}" alt="" onclick="openModal();currentSlide(3)" class="hover-shadow cursor i-swim"></div>
                <div class="column col-swim"><img v-lazy="{src: item.img4}" alt="" onclick="openModal();currentSlide(4)" class="hover-shadow cursor i-swim"></div>

                <div id="myModal" class="modal">
                    <span class="close cursor" onclick="closeModal()">&times;</span>
                    <div class="modal-content">
                        <div class="mySlides">
                            <div class="numbertext">1 / 4</div>
                            <img v-lazy="{src: item.img1}" alt="" style="width:100%">
                        </div>
                        <div class="mySlides">
                            <div class="numbertext">2 / 4</div>
                            <img v-lazy="{src: item.img2}" alt="" style="width:100%">
                        </div>
                        <div class="mySlides">
                            <div class="numbertext">3 / 4</div>
                            <img v-lazy="{src: item.img3}" alt="" style="width:100%">
                        </div>
                        <div class="mySlides">
                            <div class="numbertext">4 / 4</div>
                            <img v-lazy="{src: item.img4}" alt="" style="width:100%">
                        </div>
                        <a class="prev" onclick="plusSlides(-1)">&#10094;</a>
                        <a class="next" onclick="plusSlides(1)">&#10095;</a>
                    </div>
                </div>
            </div>
            <p class="swimming-price">{{ $t('serviceSwimmingPool.pricerate')}}</p>
            <p class="detail-price">{{ $t('serviceSwimmingPool.detailPricerate1')}}</p>
            <p class="detail-price">{{ $t('serviceSwimmingPool.detailPricerate2')}}</p>
            <div class="row">
                <div class="col-sm-6">
                    <img v-lazy="{src: require('@/assets/pool/EB44A704-43D8-4F8C-936D-4093C4EABF38.webp')}" class="img-swimming-pool">
                </div>
                <div class="col-sm-6">
                    <img v-lazy="{src: require('@/assets/pool/0750F0E1-95C6-45B2-8EA8-B445FAD06FD5.webp')}" class="img-swimming-pool">
                </div>
            </div>
            <p class="swimming-price">{{ $t('serviceSwimmingPool.detailMore')}}</p>
            <div class="detail-price">
                <p>{{ $t('serviceSwimmingPool.detailmore1')}}</p>
                <p>{{ $t('serviceSwimmingPool.detailmore2')}}</p>
                <p>{{ $t('serviceSwimmingPool.detailmore3')}}</p>
                <p>{{ $t('serviceSwimmingPool.detailmore4')}}</p>
                <p>{{ $t('serviceSwimmingPool.detailmore5')}}</p>
                <p class="margin-p">{{ $t('serviceSwimmingPool.detailmore6')}}</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            items: [
                { 
                    img1: require('../assets/pool/62BA1161-BFDF-4980-A19C-4319BB44FCCD.webp'),
                    img2: require('../assets/pool/09AACFE9-7521-48CE-9D21-99073D2F993A.webp'),
                    img3: require('../assets/pool/06DFC7E9-EEA9-4855-9145-D3F7C90E41B0.webp'),
                    img4: require('../assets/pool/7D3A9121-3835-4A3F-B614-7324DA88D87E.webp'),
                },
            ]
        }
    }
}
</script>